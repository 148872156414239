import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import * as projectStyles from "../components/project.module.css"
import { Link } from "gatsby"
import Video from "../videos/naturrepublik-30-cropped.mp4"
import videoMobile from "../videos/naturrepublik-30-cropped.mp4"
import videoTablet from "../videos/tablet-screen-recording.mp4"

const NaturrepublikPage = () => (
  <div>
    <Seo title="Webdesign für Heilpraxis Naturrepublik - Altergott Studios" lang="de" description="Eine neue Website wurde für die Naturrepublik erstellt, welche die angebotenen Therapien, Veranstaltungen und Produkte darstellt." />

    <Layout>
      <div>
        <Link to="https://www.naturrepublik.com">
          <div className={projectStyles.videoWrapper}>
            <video
              loop
              muted
              autoPlay
              playsInline
              className={projectStyles.video}
            >
              <source src={Video} type="video/mp4" />
            </video>
            <video
              loop
              muted
              autoPlay
              playsInline
              className={projectStyles.videoMobile}
            >
              <source src={videoMobile} type="video/mp4" />
            </video>
            <video
              loop
              muted
              autoPlay
              playsInline
              className={projectStyles.videoTablet}
            >
              <source src={videoTablet} type="video/mp4" />
            </video>
          </div>
        </Link>
        <p className={`${projectStyles.projectServices} ${projectStyles.uppercase}`}>Leistungen: Webdesign, SEO, Hosting, Domainregistrierung</p>

        <div className={projectStyles.projectGrid}>
          <div>
            <h3 className={projectStyles.uppercase}>Kunde</h3>

            <p>Heilpraxis Naturrepublik</p>
            <Link
              to="https://www.naturrepublik.com/"
              className={projectStyles.projectLink}
            >
              Naturrepublik.com
            </Link>
          </div>
          <div>
            <h3 className={projectStyles.uppercase}>Projektbeschreibung</h3>

            <p>Leistungen: Webdesign, SEO, Hosting, Domainregistrierung</p>
            <p>
              Eine neue Website wurde für die Naturrepublik erstellt, welche die
              angebotenen Therapien, Veranstaltungen und Produkte darstellt.
            </p>
            <p>Ein Kontaktformular ermöglicht potentiellen Kunden die Kontaktaufnahme zur Heilpraxis.</p>
            <p>Ein Newsletter Feld erlaubt es Kunden sich für den Newsletter anzumelden.</p>
          </div>
          <div>
            <h3 className={projectStyles.uppercase}>Auswirkung</h3>

            <p>
              Die neue Website ermöglicht es potentiellen Kunden sich über
              das Angebot, sowie das Personal zu informieren. Somit werden sie
              überzeugt, die Heilpraxis zu besuchen.
            </p>
            <p>Durch markentreues Design wird die  Wahrnehmung der Naturrepublik Marke gest&#246;rkt.</p>
            <p>Etwa 30% der Neukunden wurden von der Website überzeugt, die Naturrepublik zu besuchen.</p>
          </div>
        </div>
      </div>
    </Layout>
  </div>
)

export default NaturrepublikPage
